"use client";

import { LoggedUser } from "@/@codegen/supergraph";

import { create } from "zustand";

type UserStore = {
  user: LoggedUser | null;
  setUser: (newUser: LoggedUser | null) => void;
};

const useUserStore = create<UserStore>((set) => ({
  user: null,
  setUser: (newUser) => set({ user: newUser }),
}));

export function getLoggedUser() {
  return useUserStore.getState().user;
}

export function setLoggedUser(newLoggedUser: LoggedUser | null) {
  useUserStore.setState({ user: newLoggedUser });
}

function clearLoggedUser(): void {
  useUserStore.setState({ user: null });
}

export function useLoggedUser() {
  const { user, setUser } = useUserStore();
  const setLoggedUser = (newLoggedUser: LoggedUser | null) => {
    setUser(newLoggedUser);
  };
  return { loggedUser: user, setLoggedUser, clearLoggedUser };
}

export type UseLoggedUser = ReturnType<typeof useLoggedUser>;
